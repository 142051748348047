global.$ = require("jquery")
global.jQuery = global.$
global.moment = require('moment');
global.JSZip = require("jszip");

require('@fortawesome/fontawesome-free/js/all');

// IE11
var currentScript = document.currentScript || (function() {
  var scripts = document.getElementsByTagName('script');
  return scripts[scripts.length - 1];
})();
var static_url = currentScript.getAttribute('static_url');
// END IE11

require('bootstrap');
// Requires small fix: change value "datatables.net" for "datatables.net-bs4" in factory (top of file)
//   node_modules/datatables.net-buttons/js/buttons.html5.js
//   node_modules/datatables.net-buttons/js/buttons.colVis.js
// bug report will be filed with DT
require('datatables.net')();
require('datatables.net-buttons')();
require('datatables.net-buttons-bs4')();
require('datatables.net-buttons/js/buttons.colVis.js')(window, $);
require('datatables.net-buttons/js/buttons.html5.js')(window, $);

require('popper.js');
require('chart.js');
require('fullcalendar/dist/locale/fr-ca.js');
require('fullcalendar');
require('tempusdominus-bootstrap-4');
require('select2')($);
// require('./jquery.formset');
require('./jquery.inline_formset');
// require('./jquery.simple_inline_formset');
require('jquery-colpick');

global.Dropzone = require('dropzone');
Dropzone.autoDiscover = false;

require('tinymce');
require('tinymce/themes/modern/theme');
require('tinymce/plugins/link');
require('tinymce/plugins/table');
require('tinymce/plugins/textcolor');
require('tinymce/plugins/paste');

if ($('html').attr('lang') == 'fr') {
    $.extend(true, $.fn.dataTable.defaults, {
        "language": {
            "url": static_url + 'gas/js/datatables.fr_FR.json'
        },
    });
}

$.fn.dataTable.Buttons.defaults.dom.button.className = 'btn btn-primary btn-sm';
$.fn.dataTable.Buttons.defaults.dom.button.tag = 'a';
$.fn.dataTable.Buttons.defaults.dom.container.className = 'dt-button float-left';
$.fn.dataTable.ext.classes.sLength += ' float-left'
$.fn.dataTable.ext.classes.sPaging += ' float-right ml-2'
$.fn.dataTable.ext.classes.sInfo += ' d-inline'

$.extend( $.fn.dataTable.defaults, {
    order: [[ 0, "asc" ]],
    deferRender: true,
    dom: 'Blfrtip',
    lengthChange: true,
    bAutoWidth: false,
    stateSave: true,
    buttons: {
        buttons: [
            { extend: 'excel', text: '<i class="fas fa-download"></i> Excel', className: 'float-left mr-2', messageTop: null, title: null, exportOptions: {columns: ':visible'}},
            { extend: 'colvis', className: 'float-left mr-2'}
        ]
    },
    columnDefs: [
        { "orderable": false, "targets": [-1] },
    ]
});


global.fullCalendar_options = {
    themeSystem: "bootstrap4",
    lazyFetching: false,
    defaultView: 'workWeek',
    allDaySlot: false,
    eventAfterRender: function(event, element) {
        $(element).tooltip({
            title: '<div class="text-left">'+event.title.replace(/\n/g,"<br>")+'</div>',
            html: true});
    },
    minTime: "00:00:00",
    maxTime: "24:00:00",
    locale: $('html').attr('lang'),
    header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,workWeek,agendaDay'
    },
    slotDuration: '00:30:00',
    editable: false,
    selectable: false,
    eventOverlap: false,
    selectOverlap: false,
    nowIndicator: true,
    scrollTime: moment().format("HH") + ":00:00",
    // contentHeight: 'auto',
    // views: {
    //     workWeek: {
    //         type: 'agendaWeek',
    //         hiddenDays: [0, 6]
    //     }
    // },
}

piechart_options = {
    type: 'pie',
    data: {},
    options: {
        responsive: true,
        title: {
            display: true,
            text: '',
            padding: 0
        },
        legend: {
            display: false,
            onHover: function(e) {
                e.target.style.cursor = 'pointer';
            }
        },
        hover: {
            onHover: function(e) {
                var point = this.getElementAtEvent(e);
                if (point.length) e.target.style.cursor = 'pointer';
                else e.target.style.cursor = 'default';
            }
        // },
        // tooltips: {
        //     callbacks: {
        //         label: function(tooltipItem, data) {
        //             var label = data.labels[tooltipItem.index] || '';
        //             var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        //             label = label.split("/")[1];

        //             if (label) {
        //                 label += ': ';
        //             }
        //             label += value;
        //             label += "h";
        //             return label;
        //         }
        //     }
        }
    }
}

barchart_options = {
    type: 'bar',
    data: {},
    options: {
        responsive: true,
        title: {
            display: true,
            text: "",
            padding: 0
        },
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Date'
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: false
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Minutes'
                }

            }]
        },
        legend: {
            display: true,
        }
    }
}

labchart_options = {
    type: 'bar',
    data: {},
    options: {
        responsive: true,
        title: {
            display: true,
            text: "",
            padding: 0
        },
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Lab'
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: false
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Hours'
                }

            }]
        },
        legend: {
            display: false,
        }
    }
}



$(function () {


    $.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
            icons: {
                time: 'far fa-clock',
                date: 'far fa-calendar-alt',
                up: 'fas fa-arrow-up',
                down: 'fas fa-arrow-down',
                previous: 'fas fa-chevron-left',
                next: 'fas fa-chevron-right',
                today: 'far fa-calendar-check',
                clear: 'fas fa-trash-alt',
                close: 'fas fa-times'
            },
            allowInputToggle: true,
            locale: $('html').attr('lang')
    });

    $(".input-group.datetimepicker").datetimepicker({format: 'YYYY-MM-DD HH:mm'});
    $(".input-group.datepicker").datetimepicker({format: 'YYYY-MM-DD'});
    $(".input-group.timepicker").datetimepicker({format: 'HH:mm'});

    $("input.colorpicker").colpick({
        onSubmit:function(hsb,hex,rgb,el,bySetColor) {
            $(el).val('#'+hex);
            $(el).colpickHide();
        },
        layout:'hex'
    });

    $.fn.select2.defaults.set("theme", 'bootstrap4');
    // $.fn.select2.defaults.set("minimumResultsForSearch", 20);
    $.fn.modal.Constructor.prototype._enforceFocus = function() {}; // Select2 in BS modal focus bug fix

    $('form select.form-control:not([multiple])').each(function() {
        $(this).select2()
    });

    $('[data-toggle="tooltip"]').tooltip()

    $(document).on('change', '.custom-file-input', function () {
        let fileName = $(this).val().replace(/\\/g, '/').replace(/.*\//, '');
        $(this).parent('.custom-file').find('.custom-file-label').text(fileName);
    });

    tinymce.init({
        selector:'textarea:not(.nomce)',
        branding: false,
        menubar: false,
        plugins: "link table textcolor paste",
        link_assume_external_targets: true,
        link_context_toolbar: true,
        skin_url: '/static/gas/dist/css/tinymce_skins/lightgray',
        statusbar: true,
        elementpath:false,
        resize: 'both',
        toolbar: 'bold italic underline subscript superscript fontselect fontsizeselect forecolor | link table',
        paste_data_images: true,
        setup: function (editor) {
            // browser can't focus on textarea once tinymce takes over so no native browser required field validation popups
            editor.getElement().removeAttribute('required');
            editor.on('change', function () {
                editor.save();
        }); }
    });

    // https://datatables.net/plug-ins/filtering/type-based/accent-neutralise
    function removeAccents ( data ) {
        return data
            .replace( /έ/g, 'ε' )
            .replace( /[ύϋΰ]/g, 'υ' )
            .replace( /ό/g, 'ο' )
            .replace( /ώ/g, 'ω' )
            .replace( /ά/g, 'α' )
            .replace( /[ίϊΐ]/g, 'ι' )
            .replace( /ή/g, 'η' )
            .replace( /\n/g, ' ' )
            .replace( /á/g, 'a' )
            .replace( /é/g, 'e' )
            .replace( /í/g, 'i' )
            .replace( /ó/g, 'o' )
            .replace( /ú/g, 'u' )
            .replace( /ê/g, 'e' )
            .replace( /î/g, 'i' )
            .replace( /ô/g, 'o' )
            .replace( /è/g, 'e' )
            .replace( /ï/g, 'i' )
            .replace( /ü/g, 'u' )
            .replace( /ã/g, 'a' )
            .replace( /õ/g, 'o' )
            .replace( /ç/g, 'c' )
            .replace( /ì/g, 'i' );
    }
    var searchType = jQuery.fn.DataTable.ext.type.search;
    searchType.string = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data ) :
                data;
    };
    searchType.html = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data.replace( /<.*?>/g, '' ) ) :
                data;
    };

    // IE polyfill
    Object.defineProperty(Array.prototype, 'includes', {
		configurable: true,
		value: function includes (searchElement /*, fromIndex*/) {
			'use strict';
			var O = Object(this);
			var len = parseInt(O.length) || 0;
			if (len === 0) {
				return false;
			}
			var n = parseInt(arguments[1]) || 0;
			var k;
			if (n >= 0) {
				k = n;
			} else {
				k = len + n;
				if (k < 0) {
					k = 0;
				}
			}
			var currentElement;
			while (k < len) {
				currentElement = O[k];
				if (searchElement === currentElement ||
					(searchElement !== searchElement && currentElement !== currentElement)) {
					return true;
				}
				k++;
			}
			return false;
		},
		writable: true
	});

    // IE polyfill
    if (!String.prototype.includes) {
        String.prototype.includes = function(search, start) {
            'use strict';

            if (search instanceof RegExp) {
              throw TypeError('first argument must not be a RegExp');
            }

            if (start === undefined) {
                start = 0;
            }
            return this.indexOf(search, start) !== -1;
        };
    }

});
