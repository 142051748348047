/**
 * Based on :
 * jQuery Formset 1.1
 * @author Stanislaus Madueke (stan DOT madueke AT gmail DOT com)
 * @requires jQuery 1.2.6 or later
 *
 * Modified by:
 * @author Jean-Philippe Laverdure
 */
;(function($) {
    $.fn.formset = function(opts)
    {
        var options = $.extend({}, $.fn.formset.defaults, opts),
            flatExtraClasses = options.extraClasses.join(' '),
            $$ = $(this),

            updateElementIndex = function(elem, prefix, ndx) {
                var idRegex = new RegExp('(#?' + prefix + '-[0-9]+-)');
                var replacement = prefix + '-' + ndx + '-';
                if (elem.attr("for")) elem.attr("for", elem.attr("for").replace(idRegex, replacement));
                if (elem.attr('id')) elem.attr('id', elem.attr('id').replace(idRegex, replacement));
                if (elem.attr('name')) elem.attr('name', elem.attr('name').replace(idRegex, replacement));
                if (elem.attr('href')) elem.attr('href', '#' + elem.attr('href').replace(idRegex, replacement));
                if (elem.attr('data-target')) elem.attr('data-target', elem.attr('data-target').replace(idRegex, replacement));
            },

            hasChildElements = function(row) {
                return row.find('input,select,textarea,label').length > 0;
            },

            insertDeleteLink = function(row) {
                row.find('.form-group').append('<button type="button" class="btn btn-danger ' + options.deleteCssClass + ' ml-3" title="' + options.deleteText + '"><i class="' + options.deleteIcon +'"></i></button>');
                row.find('button.' + options.deleteCssClass).click(function() {
                    var row = $(this).parents('.' + options.formCssClass),
                        del = row.find('input:checkbox[id $= "-DELETE"]');

                    // We're dealing with an inline formset; rather than remove
                    // this form from the DOM, we'll mark it as deleted and hide
                    // it, then let Django handle the deleting:
                    del.prop('checked', true);
                    row.addClass("d-none");
                    $("#item-number-badge").text($('.' + options.formCssClass).not(".d-none").length);
                    handleEmptyForm();
                });
            },

            handleEmptyForm = function() {
                if (0 < $('.' + options.formCssClass).not(".d-none").length) {
                    $("#order-form").find('button[type="submit"]').prop("disabled", false);
                    $("#empty-cart-alert").addClass('d-none');
                } else {
                    $("#order-form").find('button[type="submit"]').prop("disabled", true);
                    $("#empty-cart-alert").removeClass('d-none');
                }
            };


        $$.each(function(i) {
            var row = $(this);
            var del = row.find('input:checkbox[id $= "-DELETE"]').closest('.input-group');
            if (del.length) {
                // If you specify "can_delete = True" when creating an inline formset,
                // Django adds a checkbox to each form in the formset.
                // Hide the default checkbox:
                del.addClass("d-none");
            }
            // If formset is marked for deletion, hide it
            if (row.find('input:checkbox[id $= "-DELETE"]').prop("checked") == true) {
                row.addClass("d-none");
            }
            if (hasChildElements(row)) {
                insertDeleteLink(row);
                row.addClass(options.formCssClass);
                // applyExtraClasses(row, i);
            }
        });

        if ($$.length) {
            var template;

            // Use the last form in the formset; this works much better if you've got
            // extra (>= 1) forms (thanks to justhamade for pointing this out):
            var last_row = $('.' + options.formCssClass + ':last');

            template = last_row.clone(true);
            template.removeAttr('id').removeClass('d-none');
            $("#item-number-badge").text($('.' + options.formCssClass).not(".d-none").length);
            handleEmptyForm();

            var sel2_on = last_row.find('select').data('select2');
            if (sel2_on) {
                last_row.find('select').select2('destroy');
            }
            last_row.find('.input-group.datepicker').datetimepicker('destroy');
            last_row.find('.input-group.datetimepicker').datetimepicker('destroy');
            template = last_row.clone(true).removeAttr('id').removeClass('d-none');

            // Special case for select2
            if (sel2_on) {
                last_row.find('select').select2();
            }

            // FIXME: Perhaps using $.data would be a better idea?
            options.formTemplate = template;

            $(document).on('click', ".add-formset-btn", function(event) {
                event.preventDefault();

                var formCount = parseInt($('#id_' + options.prefix + '-TOTAL_FORMS').val()),
                    row = options.formTemplate.clone(true);

                // applyExtraClasses(row, formCount);
                row.insertBefore($('.' + options.formCssClass + ':last'));
                row.find('input,select,textarea,label,a.nav-link,.datepicker').each(function() {
                    updateElementIndex($(this), options.prefix, formCount-1);
                });
                last_row.find('input,select,textarea,label,a.nav-link,.datepicker').each(function() {
                    updateElementIndex($(this), options.prefix, formCount);
                });
                row.find('select').val($(this).data('id'));
                row.find('input[id $= "-qty"]').val(1); // SET INITIAL QUANTITY to 1
                row.find(".input-group.datetimepicker").datetimepicker({format: 'YYYY-MM-DD HH:mm', allowInputToggle: true});
                row.find(".input-group.datepicker").datetimepicker({format: 'YYYY-MM-DD', allowInputToggle: true});
                row.find('select').select2();

                $('#id_' + options.prefix + '-TOTAL_FORMS').val(formCount + 1);

                $("#item-number-badge").text($('.' + options.formCssClass).not(".d-none").length);
                handleEmptyForm();
            });
        }

        return $$;
    };

    /* Setup plugin defaults */
    $.fn.formset.defaults = {
        prefix: 'form',                  // The form prefix for your django formset
        formTemplate: null,              // The jQuery selection cloned to generate new form instances
        addText: 'add another',          // Text for the add link
        deleteText: 'Remove',            // Text for the delete link
        deleteIcon: 'fas fa-trash',      // Icon for the delete link
        addCssClass: 'add-row',          // CSS class applied to the add link
        deleteCssClass: 'delete-row',    // CSS class applied to the delete link
        formCssClass: 'dynamic-form',    // CSS class applied to each form in a formset
	    extraClasses: [],                // Additional CSS classes, which will be applied to each form in turn
        added: null,                     // Function called each time a new form is added
        removed: null                    // Function called each time a form is deleted
    };
})(jQuery);
